import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/member`;

export default {
  options(payload) {
    payload = {
      ...payload,
    };
    return Client.post(resource, payload, {
      headers: {
        action: "list-option",
      },
    });
  },
};
