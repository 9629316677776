import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/bet-match`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params,
      userId: payload.userId,
    }, {
      headers: {
        action: "list",
      },
    });
  },
  update(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "update",
      },
    });
  },
  updateResult(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "update-result",
      },
    });
  },
  updateStatus(id, isEnable) {
    return Client.post(resource, {
      id: id,
      isEnable: isEnable,
    }, {
      headers: {
        action: "update-status",
      },
    });
  },
};
