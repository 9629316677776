export default [
  {
    path: '/bet-setting',
    name: 'bet-setting',
    component: () => import('@/views/betSetting/BetSetting.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      resource: 'bet-setting',
      navActiveTab: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.betSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/round-up-odds',
    name: 'round-up-odds',
    component: () => import('@/views/betSetting/roundUpOdds/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.roundUpOdds',
      resource: 'bet-setting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'round-up-odds',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.roundUpOdds',
          active: true,
        },
      ],
    },
  },
  {
    path: '/limit-odds',
    name: 'limit-odds',
    component: () => import('@/views/betSetting/limitOdds/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.limitOdds',
      resource: 'bet-setting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'limit-odds',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.limitOdds',
          active: true,
        },
      ],
    },
  },
]
