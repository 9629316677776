import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/setting`;

export default {
  show(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'betting-setting'
      }
    });
  },
  update(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'store-betting-setting'
      }
    });
  },
  showRoundUp(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'get-odd-round-setting'
      }
    });
  },
  updateRoundUp(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: 'store-odd-round-setting'
      }
    });
  },
  showLimit(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'get-bet-limit-odd-setting'
      }
    });
  },
  updateLimit(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: 'store-bet-limit-odd-setting'
      }
    });
  },
};
