import moment from 'moment';
import currency from "currency.js";
import { decamelize } from 'humps';
import store from '@/store';

export const caclulateOffset = (page, limit) => {
  return (page - 1) * limit;
};

export const calculateDatesByPeriod = (period) => {
  if (period === 'today') {
    return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else if (period === 'yesterday') {
    return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'tomorrow') {
    return [moment().add(1, 'day').format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'thisWeek') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastWeek') {
    return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'thisMonth') {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
  }

  return [];
}

export const currencyFormat = (value, currencyCode) => {
  if (typeof value === 'undefined') {
    return '';
  }
  let val = value;
  let symbol = '';

  if (currencyCode === 840) {
    const USD = value => currency(value);
    return USD(value).format();
  } else if (currencyCode === 116) {
    const RIEL = value => currency(value, { precision: 2, symbol: '៛' });
    return RIEL(value).format()
  }

  return currency(value);
}

export const prepareSearchParams = (payload, filtertype = "AND") => {
  let filterArr = [];

  if (payload.searchFields) {
    payload.searchFields.forEach(item => {
      if (!item.skip) {
        if (payload[item.key] || false) {
          let search = payload[item.key];
          switch (item.cast) {
            case "boolean":
              search = (search == 1 ? true : false);
              break;
          }

          filterArr.push({
            searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
            searchoperator: item.operator || "=",
            search: search,
          });
        }
      }
    });
  }
  const params = {
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    filtertype: filtertype,
    filters: filterArr,
  };

  return params;
}

export const preparePaginationParams = (payload, filtertype = "AND") => {
  const params = {
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    filtertype: filtertype,
    filters: [],
  };

  return params;
}

export const prepareOptionFilterParams = (payload, queries = [], filtertype = "AND", sortby = "id", sort = "asc") => {
  let filters = [{
    searchby: "deleted_at",
    searchoperator: payload.achieved ? "!=" : "=",
    search: null,
  }];
  if (payload.includeIds) {
    filters.push({
      searchby: "id",
      searchoperator: "in",
      search: payload.includeIds.split(",")
    });
  } else {
    if (payload.excludeIds) {
      filters.push({
        searchby: "id",
        searchoperator: "notin",
        search: payload.excludeIds.split(",")
      });
    }
  }

  let params = {
    limit: 25,
    sortby: sortby,
    sort: sort,
    filtertype: "AND",
    filters: [{
      filtertype: "AND",
      filters: filters,
    }, {
      filtertype: filtertype,
      filters: queries,
    }],
  };

  return params;
}