import ProfileRepository from "./Api/ProfileRepository";
import CurrencyRepository from "./Api/CurrencyRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import CompanyRepository from "./Api/CompanyRepository";
import SeniorRepository from "./Api/SeniorRepository";
import MasterRepository from "./Api/MasterRepository";
import AgentRepository from "./Api/AgentRepository";
import PlayerRepository from "./Api/PlayerRepository";
import MemberRepository from "./Api/MemberRepository";
import AccountRepository from "./Api/AccountRepository";
import TransactionRepository from "./Api/TransactionRepository";
import BalanceReportRepository from "./Api/BalanceReportRepository";
import DashboardRepository from "./Api/DashboardRepository";
import BetSettingRepository from "./Api/BetSettingRepository";
import BetHistoryRepository from "./Api/BetHistoryRepository";
import ToolRepository from "./Api/ToolRepository";
import TimeRepository from "./Api/TimeRepository";
import MatchRepository from "./Api/MatchRepository";
import LiveRepository from "./Api/LiveRepository";
import AnnocementRepository from "./Api/AnnocementRepository";

const repositories = {
    'dashboard': DashboardRepository,
    'profile': ProfileRepository,
    'currency': CurrencyRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'company': CompanyRepository,
    'senior': SeniorRepository,
    'master': MasterRepository,
    'agent': AgentRepository,
    'player': PlayerRepository,
    'member': MemberRepository,
    'account': AccountRepository,
    'transaction': TransactionRepository,
    'balanceReport': BalanceReportRepository,
    'betSetting': BetSettingRepository,
    'betHistory': BetHistoryRepository,
    'tool': ToolRepository,
    'time': TimeRepository,
    'match': MatchRepository,
    'live': LiveRepository,
    'annoucement': AnnocementRepository,
};

export default {
    get: name => repositories[name]
};
