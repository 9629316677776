export default [
  {
    path: '/match',
    name: 'list-match',
    component: () => import('@/views/match/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.match',
      navActiveLink: 'match',
      navActiveTab: 'match',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listMatch',
          active: true,
        },
      ],
    },
  },
]
