export default [
  {
    path: '/tool',
    name: 'list-tool',
    component: () => import('@/views/betSetting/toolSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.toolSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'tool',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTool',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tool/create',
    name: 'create-tool',
    component: () => import('@/views/betSetting/toolSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.toolSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'tool',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listTool',
          to: { name: 'list-tool' }
        },
        {
          text: 'breadcrumb.createTool',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tool/:id/update',
    name: 'update-tool',
    component: () => import('@/views/betSetting/toolSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.toolSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'tool',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listTool',
          to: { name: 'list-tool' }
        },
        {
          text: 'breadcrumb.updateTool',
          active: true,
        },
      ],
    },
  },
]
