import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/announce`;

export default {
  show() {
    return Client.post(resource, {}, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store",
      },
    });
  },
};
