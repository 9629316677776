export default [
  {
    path: '/time',
    name: 'list-time',
    component: () => import('@/views/betSetting/timeSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.timeSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'time',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTime',
          active: true,
        },
      ],
    },
  },
  {
    path: '/time/create',
    name: 'create-time',
    component: () => import('@/views/betSetting/timeSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.timeSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'time',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listTime',
          to: { name: 'list-time' }
        },
        {
          text: 'breadcrumb.createTime',
          active: true,
        },
      ],
    },
  },
  {
    path: '/time/:id/update',
    name: 'update-time',
    component: () => import('@/views/betSetting/timeSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.timeSetting',
      navActiveLink: 'bet-setting',
      navActiveTab: 'time',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listTime',
          to: { name: 'list-time' }
        },
        {
          text: 'breadcrumb.updateTime',
          active: true,
        },
      ],
    },
  },
]
