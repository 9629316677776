export default [
  {
    path: '/live',
    name: 'list-live',
    component: () => import('@/views/live/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.live',
      navActiveLink: 'list-live',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLive',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live/create',
    name: 'create-live',
    component: () => import('@/views/live/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.live',
      navActiveLink: 'list-live',
      resource: 'bet-setting',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listLive',
          to: { name: 'list-live' }
        },
        {
          text: 'breadcrumb.createLive',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live/:id/update',
    name: 'update-live',
    component: () => import('@/views/live/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.live',
      navActiveLink: 'list-live',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listLive',
          to: { name: 'list-live' }
        },
        {
          text: 'breadcrumb.updateLive',
          active: true,
        },
      ],
    },
  },
]
